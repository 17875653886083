export function Privacy() {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>Effective date: May 15, 2024</p>
      <p>Here are some important definitions to help you understand our terms and this Privacy Policy:</p>
      <p>Uncut Creative LLC and its affiliates are collectively referred to as "Trinity Ai," "we," "us," and "our." Our Trinity Ai mobile application is referred to as "Trinity Ai" or the "application." The term "users" refers to "you" or "your," as applicable. The act of downloading, installing, registering with, accessing, or using the application in any other manner is referred to as "Use." Information linked to an identified or identifiable individual and protected under data protection laws is referred to as "Personal Data."</p>
      <p>Please note the operation of Trinity Ai's Personal Ai Photographer feature. Trinity Ai employs a Stable Diffusion Neural Network Model to create personalized Personal Ai Photographer (also known as "Avatars") for users. To generate these Avatars, users are required to upload 1-10 photos and specify their gender. The model is then retrained based on this information to produce customized Avatars. Once the Avatars are generated, the retrained model and uploaded photos are deleted permanently from our servers.</p>
      <p>We assure you that we do not use your Personal Data for the training or creation of our other artificial intelligence products. Though we make an effort to regulate the parameters of the Stable Diffusion model, there may still be instances where you encounter content that you consider inappropriate. To fully understand the services we offer, we recommend that you review our Terms of Use.</p>
      <p>Privacy is a top priority for us and we are committed to protecting it through compliance with our Privacy Policy. This policy explains how we handle your Personal Data, and provides you with the tools to manage it effectively. Please carefully read through this policy to understand our data collection, usage, and sharing practices. If you do not wish for your Personal Data to be processed as described in this policy, please refrain from using Trinity Ai.</p>
      <p>By Using Trinity Ai, you understand and agree that we are providing you with tools for editing and adjusting photos and videos that you upload, and, if you so request, for generating or creating new content from them, for example with our Personal Ai Photographer feature. If you have any questions about this Privacy Policy or Trinity Ai, please contact us at support@trinityapp.io (for additional contact information, please, see Section 13 of this Privacy Policy “How to Contact Us”).</p>
      <p>This Privacy Policy DOES NOT apply to the information that:</p>
      <p>We collect offline or on any of our other apps or websites, including websites you may access through Trinity Ai.</p>
      <p>You provide to or that is collected by any third party (e.g., any third-party application or website, even if the link to it is accessible through Trinity Ai).</p>

      <h2>1. INFORMATION WE COLLECT AND HOW WE COLLECT IT</h2>
      <p>We may collect several types of information, including but not limited to Personal Data, from and about you:</p>
      <p>Directly from you when you provide it to us.</p>
      <p>Automatically when you Use Trinity Ai.</p>
      <p>Information collected automatically may include Usage details and internet protocol (“IP”) addresses.</p>
      <p>From third parties, for example, our Service Providers (as defined below).</p>

      <h2>2. INFORMATION YOU PROVIDE TO US DIRECTLY</h2>
      <p>While Using Trinity Ai, you tend to give us certain information, including Personal Data like photos, videos uploaded to the app to use the Personal Ai Photographer feature. It's important to note that we always remove all metadata, including geotags, before temporarily storing the files on our systems.</p>
      <p>The app's editing features may utilize TrueDepth API technologies provided by Apple, which reveal information about facial positioning, orientation, and topology. This information is processed only on-device and is never stored on our servers. To learn more about TrueDepth API's handling of your photos and videos, you can check out the link.</p>
      <p>Access to your photos and videos is only granted after you give us permission through the request displayed on your mobile device. You can revoke this permission by adjusting the settings on your mobile device, here's how</p>
      <p>For iOS devices: go to Settings &gt; Privacy &gt; Camera/Photos, toggle Trinity Ai's permission on/off.</p>
      <p>For Android devices: go to Settings &gt; Apps &gt; Trinity Ai &gt; Permissions, and change the permission setting.</p>
      <p>When using the Personal Ai Photographer feature, we'll ask for your gender, which the AI model uses solely to generate Avatars that reflect the indicated gender. If you identify as female, you'll receive Avatars that are more feminine, for example. If you create an account with Trinity Ai, we'll ask for your email, which will be used for the sign-in process.If you reach out to us, we'll keep records of your correspondence, including any contact details you've provided, to address your concerns and monitor our responses to inquiries from you and other users in relation to Trinity Ai.</p>

      <h2>3. INFORMATION WE COLLECT AUTOMATICALLY</h2>
      <p>When you use Trinity Ai, certain data may be automatically collected from you. This information is necessary for the operation of Trinity Ai and may be used for in-app analytics and marketing purposes, if you have consented. The data collected may include:</p>
      <p>Device information such as IP address, device identifier, operating system, and mobile network info</p>
      <p>Usage details such as frequency of use, areas and features accessed, and engagement with specific features</p>
      <p>(With consent) IDFA or Android Advertising ID</p>
      <p>In-app purchase details</p>
      <p>This data helps us improve Trinity Ai and deliver better services, including audience estimation and understanding user behavior and preferences. We use third-party analytics tools such as Google Firebase, Meta, AppsFlyer, and Amplitude to measure traffic and usage trends. These tools collect pseudonymized user IDs and information about the pages you visit, your actions within the app, and your subscription type. The data is collected and analyzed in an aggregated manner and cannot be used to identify a specific user. If you wish to opt-out of this data collection and processing, you may do so through the privacy settings on your device or by contacting support@trinityapp.io</p>

      <h2>4. HOW WE PROCESS YOUR PHOTOS AND VIDEOS</h2>
      <p>At Trinity Ai, we understand the importance of privacy and have taken measures to ensure that your personal data remains secure. The majority of processing activities happen on your device, except for when creating Personal Ai Photographer. In order to generate these personalized Avatars, we require you to upload 1-10 photos, which are temporarily stored on our servers (hosted by Amazon Web Services in the USA). Once the Avatars are created, your original photos are promptly deleted.</p>
      <p>Your purchased Avatars will be saved on our servers for ongoing access through Trinity Ai until you choose to delete them.</p>
      <p>Please note that our commitment to privacy extends to all aspects of your data:</p>
      <p>We collect and use face data solely for the purpose of training a Stable Diffusion model to generate AI images and for refining these images. This data is stored securely on Amazon Web Services (AWS) servers in the USA and is not shared with any third parties. Users have control over their face data and can delete it by removing the associated model or their account.</p>
      <p>We do not use your photos, videos, or Avatars to identify you as an individual.</p>
      <p>We do not utilize your data for authentication, marketing, or advertising purposes, or in any way that would target you specifically.</p>
      <p>Your photos, videos, and Avatars will not be used to build user profiles or to help third parties identify you or reconstruct your profile.</p>
      <p>We will not share, transfer, sell, or provide access to your data to any advertising platforms, analytics providers, data brokers, or information resellers.</p>
      <p>We value your privacy and are committed to protecting your personal data in compliance with applicable laws and regulations. This policy explains the types of data we process, how we process it, the purposes for data processing, and how you can request data deletion.</p>
      <p>What Data We Process</p>
      <p>Photos and Avatars: Images you upload to our platform for creating personalized content, such as Avatars or enhanced images.</p>
      <p>User Information: Basic details like email addresses or account details for identification and communication purposes.</p>
      <p>Generated Content: Images or Avatars created using your uploaded photos.</p>
      <p>How We Process Your Data</p>
      <p>Uploaded photos and any data associated with creating Avatars or images are temporarily stored on our servers to process your requests.</p>
      <p>Once the processing is complete and your content is delivered, the data is securely stored only as necessary to provide continued access to your generated content.</p>
      <p>All data processing is conducted in compliance with industry-standard security measures to ensure your data remains protected.</p>
      <p>Purposes for Data Processing</p>
      <p>Image Enhancement and Avatar Creation: To generate personalized content based on your uploads.</p>
      <p>Improvement of Services: To analyze usage trends and enhance our algorithms and features.</p>
      <p>Compliance: To adhere to legal obligations and safeguard the security of our platform.</p>
      <p>Data Deletion</p>
      <p>You can delete your uploaded photos, Avatars, or generated content at any time directly through the app.</p>
      <p>Once deleted, all associated data, including any data used to create Avatars or images, is automatically and permanently erased from our servers.</p>
      <p>Should you need further assistance or confirmation of data deletion, you can contact us at support@trinityapp.io.</p>
      <p>Your Rights</p>
      <p>You have the right to request access to, correction of, or deletion of your data at any time.</p>
      <p>To exercise these rights, please use the data management features in the app or contact us at the email provided above.</p>
      <p>We are dedicated to ensuring transparency and compliance with all applicable data protection laws. For any questions or concerns about this policy, feel free to reach out to us.</p>

      <h2>5. THE PURPOSES AND OUR LEGAL BASES FOR PROCESSING YOUR PERSONAL DATA</h2>
      <p>The use of your information other than photos and videos by Trinity Ai is for the following purposes:</p>
      <p>To fulfill our obligations and enforce our rights from any contracts between you and us (e.g., Terms of Use)</p>
      <p>To provide support and respond to your inquiries. If you share your Personal Data (such as name and contact info) for help with Trinity Ai, we'll use it to address your concerns, respond to inquiries, and monitor/improve our support for users. It's in our interest to offer quality support.</p>
      <p>To notify you of changes or updates to Trinity Ai and its products/services, including through technical notices, account updates, security alerts, and support/admin messages sent through in-app or push notifications (you can opt-out of push notifications in your device settings). Keeping you informed is in our interest.</p>
      <p>To show you Trinity Ai and its contents, and any other information, products, or services you request. We have a contractual obligation to provide you with our services.</p>
      <p>To monitor aggregated metrics such as user count, traffic, and demographic patterns (using Service Providers as detailed in Section 7). Understanding our audience through analytics is in our interest for improving our product.</p>
      <p>To improve, test, and monitor the effectiveness of Trinity Ai. Understanding our audience through analytics is in our interest for improving our product.When you choose Personal Ai Photographer, you provide your gender information which is used to generate Avatars based on your photos. This data is deleted after Avatar generation. We have a contractual obligation to provide you with this service.</p>
      <p>To store your original photo for 24 hours for faster and more convenient use of Art Styles. The original photo will be deleted after 24 hours.</p>
      <p>To provide personalized content/information to you, including online ads/marketing with your explicit consent.To find similar audiences for marketing purposes with user consent, sharing Use details, device information, and in-app purchase details with advertising partners.</p>
      <p>In any other ways described in our Privacy Policy.</p>
      <p>We will only process your Personal Data in ways compatible with its collection/authorization and limit its processing to what's strictly necessary for our purposes. For any new processing not compatible with these purposes, we'll seek separate explicit consent. We take reasonable steps to ensure your Personal Data is reliable, accurate, complete, and current for its intended use, and only collect what's necessary for our purposes.</p>

      <h2>6. YOUR RIGHTS</h2>
      <p>Access, Correction, Modification, and Erasure</p>
      <p>If you'd like to request access, correction, modification, erasure, or portability of any Personal Data you've provided to us, or if you'd like to request deletion of your account on either iOS or Android, you can send an email to support@trinityapp.io. However, we may not be able to make changes if it would go against any legal requirements or make the information inaccurate.</p>
      <p>Rights for EEA/UK Residents</p>
      <p>Residents of the European Economic Area (EEA) and the United Kingdom (UK) have certain rights under the General Data Protection Regulation (GDPR), such as:</p>
      <p>Access to Personal Data: You have the right to request information on whether we have any Personal Data about you and to access the Personal Data you've provided and that we have on you.</p>
      <p>Correction of Personal Data: You are responsible for ensuring that your Personal Data is accurate. If you believe it's incomplete or inaccurate, you have the right to request correction.</p>
      <p>Restriction of Processing: You can request that we limit the processing of your Personal Data if, for example, you contest its accuracy.</p>
      <p>Erasure of Personal Data: If your Personal Data is no longer necessary, you can request its erasure. This includes cases where you've withdrawn your consent, object to processing, or processing doesn't comply with GDPR.</p>
      <p>Right to Data Portability: If your Personal Data is processed with your consent or as part of a contract, you can request it in a structured and machine-readable format, and even ask for it to be sent to another party.</p>
      <p>Right to Object to Processing: If your Personal Data is processed based on our legitimate interest, you have the right to object.</p>
      <p>Right to Withdraw Consent: If you've given your consent for a specific processing purpose, you can withdraw it at any time.</p>
      <p>Right to Complain to a Supervisory Authority: You have the right to file a complaint with a local data protection authority if you believe there's been an infringement of data protection laws.</p>
      <p>Rights for Residents of Certain US States</p>
      <p>Residents of California, Virginia, Colorado, Utah, and Connecticut may have rights under state comprehensive privacy law, including:</p>
      <p>Requesting Categories of Personal Information Collected: Trinity Ai will provide information on the types of data we collect.</p>
      <p>Requesting Sources of Personal Information: Trinity Ai will provide information on the tools and organizations we use to collect data.</p>
      <p>Requesting Business Purpose of Collecting Personal Information: Trinity Ai will provide information on the reasons for collecting data.</p>
      <p>Requesting Categories of Third Parties Receiving Personal Information: Trinity Ai will provide information on other organizations that may receive your Personal Information.</p>
      <p>Requesting Specific Pieces of Personal Information: Trinity Ai will provide your Personal Information.</p>
      <p>Requesting Deletion of Personal Information: We may keep some Personal Information for legal reasons, but we'll accommodate deletion requests when required.</p>

      <h2>7. SHARING OF COLLECTED INFORMATION</h2>
      <p>We do not rent or sell your Personal Data, including photos and videos, to any third parties outside the Company or its Affiliates (as defined below).</p>
      <p>Parties with whom we may share your Personal Data.</p>
      <p>We also may share your Personal Data, and other collected information with third-party organizations such as contractors and service providers that we use to support our business and who are bound by confidentiality and data protection terms (consistent with this Privacy Policy) to keep your Personal Data confidential and use it only based on our instructions (“Service Providers”).</p>
      <p>Such Service Providers include:</p>
      <p>cloud providers Google Cloud Platform and Amazon Web Services, which we use when you choose to upload your photos or videos to Trinity Ai,third-party analytics, as specified in this Privacy Policy, email delivery services, and Third-party advertising partners (for information on how third-party advertising partners collect and process your data and to opt out of such collection and processing, please visit this link). Upon your consent, we send some of your Personal Data (e.g., your device information and details about your in-app purchases) for marketing and promotional purposes to our advertising partners (e.g., AppsFlyer, Meta).</p>
      <p>By these means, we are able to reach you and people like you on various platforms.For the avoidance of doubt, we do not share or transfer your photos, videos and Avatars to third parties (except temporarily storing your photos, videos and Avatars to our cloud providers Google Cloud Platform and Amazon Web Services to provide online processing function, and thereafter deleting such photos, videos and Avatars as described in this Privacy Policy). We use only secure places of storage, such as Google Cloud Platform and Amazon Web Services. Google LLC and Amazon Web Services, Inc. are located in the United States of America.</p>
      <p>What happens in the event of a change of control. If we sell or otherwise transfer part or the whole of the Company or our assets to another organization (e.g., in the course of a transaction like a merger, divestiture, restructuring, reorganization, acquisition, bankruptcy, dissolution, liquidation), your Personal Data and any other collected information may be among the items sold or transferred.</p>
      <p>Responding to legal requests and preventing harm. We may access, preserve and share your information in response to a legal (like a search warrant, court order or subpoena), government or regulatory request if we have a good faith belief that the law requires us to do so. This may include responding to legal, government or regulatory requests from jurisdictions where we have a good faith belief that the response is required by law in that jurisdiction, affects users in that jurisdiction, and is consistent with internationally recognized standards.</p>
      <p>We may also access, preserve and share information when we have a good faith belief it is necessary to: (i) detect, prevent and address fraud and other illegal activity; (ii) protect ourselves, you and others, including as part of investigations; and (iii) prevent death or imminent bodily harm. Information we receive about you may be accessed, processed and retained for an extended period of time when it is the subject of a legal request or obligation, governmental investigation, or investigations concerning possible violations of our terms or policies, or otherwise to prevent harm.</p>

      <h2>8. DATA SECURITY</h2>
      <p>We employ reasonable information security measures to help ensure the protection of the information we collect. However, despite our efforts, transmitting information over the internet carries some risk and we cannot guarantee absolute security. You are responsible for any transmission of your information at your own risk. Our security measures include encryption of personal data, secure storage behind firewalls, and organizational and legal measures.</p>
      <p>Our employees and those at our subsidiaries are held to strict standards of data protection and have limited access to your personal data. We regularly assess our data protection practices and will conduct a privacy audit if necessary. Your privacy settings may be impacted by changes to social media services, but we cannot be held responsible for the security measures of other organizations.In the event of a security breach, we will take reasonable steps to address it, including posting a notice, attempting to notify you by email, and taking further action as specified in this Privacy Policy and applicable law. You can report a security incident by contacting us at support@trinityapp.io.We welcome your feedback and technical issues can be reported through the "Send Feedback" button in the Trinity Ai settings. This information, along with device information, will only be used to resolve your issue and will not be used to identify users individually. You may receive electronic communications from us, including in-app notices, emails, and marketing materials with your consent. Our communications may include updates to Trinity Ai, its in-app subscriptions, and this Privacy Policy.</p>

      <h2>9. DATA RETENTION</h2>
      <p>Your Personal Data, other than photos, videos and Avatars. Normally, the retention term would be the term of your account existence. It means that we will retain your Personal Data, other than your photos, videos or Avatars, until you delete your account or request us to delete your data (by contacting us at support@trinityapp.io).</p>
      <p>Please note that deletion of Trinity Ai application does not imply the deletion of your account and your data!Your original photos and videos that you upload for editing are processed on your device only and are not stored with our servers unless you choose to apply Art Styles to your photos. In case of applying Art Styles, your original photo is stored on our server for 24 hours. We need it to allow you edit photos more smoothly, so that you do not have to re-upload your original photo multiple times to apply different Art Styles.</p>
      <p>Moreover, such an approach helps you spend less Internet traffic and makes the processing of Art Styled photos faster.Your original photos that you upload to generate Avatars are deleted immediately after successful Avatars generation. At the same time, your Avatars are stored on our servers (provided by Google Cloud Platform (Ireland and USA) and Amazon Web Services (USA)) to be available to you at any time from any device until you decide to delete them (e.g., within the app or via a request you send us at support@trinityapp.io).</p>
      <p>We may retain your Personal data in connection with your privacy-related requests and communications with us, if any, as necessary to comply with our legal obligations, to resolve disputes, and to enforce our agreements. Even if we delete some or all of your Personal Data, we may continue to retain and use anonymized data previously collected that can no longer be used for personal identification.</p>

      <h2>10. CROSS-BORDER TRANSFERS</h2>
      <p>Your Personal Data may be transferred to and stored in the United States or other countries, as the Company is incorporated in the United States. If transfers of personal data from the EEA to countries outside the EEA are required, we ensure that appropriate safeguards are in place to maintain the protection of your information. This includes signing the Standard Contractual Clauses of the European Commission as required by the EEA GDPR. In the case of transfers of personal data to countries outside the United Kingdom, we ensure the protection of your information through the signing of the UK Addendum to the EU Standard Contractual Clauses or the UK International Data Transfer Agreement, whichever is more appropriate in the given situation, as required under the UK GDPR. We may also rely on adequacy decisions adopted by the UK authorities to guarantee the protection of your personal data. Our Trinity Ai operation uses AWS servers located in the USA, while our analytics operations are processed on servers provided by AWS (located in the USA) and Google LLC (located in the Republic of Ireland). If you require further information, please contact support@trinityapp.io</p>

      <h2>11. CHILDREN</h2>
      <p>The Age Restriction for Trinity Ai. Our service is not designed for, nor intended to be used by, individuals under the age of 13. We do not knowingly collect personal information from minors, and we do not allow anyone under 13 to use Trinity Ai. If you are under 13, please do not use our platform or provide any information about yourself to us. If we discover that we have received information from someone under 13 without parental consent, we will promptly delete it. If you think that we may have information from a child under 13, please contact us using the information in the "How to Contact Us" section.For EEA Residents: In accordance with the GDPR, you must be at least 16 years old to use Trinity Ai. If you are younger than 16 and reside in the EEA, you are prohibited from using our platform. If you become aware of someone younger than 16 using Trinity Ai, please notify us by contacting the information in the "How to Contact Us" section, and we will take the necessary steps to delete their information.</p>

      <h2>12. OTHER WEBSITES AND SERVICES</h2>
      <p>We take no responsibility for the privacy practices of websites and services linked to or from Trinity Ai, including the information and content they contain. If you come across a website or service linked from Trinity Ai, we strongly advise you to carefully read their privacy policy before sharing any information through it.</p>

      <h2>13. HOW TO CONTACT US, EEA/UK REPRESENTATIVE, AND DATA PROTECTION OFFICER</h2>
      <p>Contact Information. For any inquiries regarding this Privacy Policy or Trinity Ai, kindly reach us at support@trinityapp.io or our mailing address: Uncut Creative LLC, 330 N Gould ST, Ste R, Sheridan, Wyoming 82801</p>
      <p>Representative for EEA/UK. If you are a resident of the EEA or UK and have any questions or concerns about this Privacy Policy or Trinity Ai, please send an email to support@trinityapp.io</p>
      <p>Data Protection Officer. Should you reside in the EEA or UK and wish to exercise your rights under Section 6, or have any queries about this Privacy Policy or Trinity Ai, you may reach our data protection officer at support@trinityapp.io</p>

      <h2>14. CHANGES TO OUR PRIVACY POLICY</h2>
      <p>The revision date of this Privacy Policy is indicated at the top of the page. The company may make updates to the Policy periodically. Some changes don't require your approval, such as when we add a new processing purpose that aligns with existing ones or that is within users' expectations. However, if changes may impact your rights and freedoms, such as a new processing purpose that is incompatible with existing ones, a new legal basis, or a new type of personal data collection, and not within users' expectations, we will seek your separate consent for these changes. If you do not receive a request for your consent or decline to give it, these changes will not apply to you. This could negatively affect some Trinity Ai services that require your consent for the changes.</p>
    </div>
  );
};
