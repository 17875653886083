/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import ai from '../assets/ai.png';
import icon from '../assets/icon.jpg';
import pet from '../assets/pet.png';
import studio from '../assets/studio.png';
import { Contact } from './Contact';
import { Privacy } from './Privacy';
import { Terms } from './Terms';
import VideoBackground from './VideoBackground';

function App() {
  const [showMenu, setShowMenu] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showContact, setShowContact] = useState(false);

  useEffect(() => {
    const hash = window.location.hash
    if (hash === "#privacy") {
      setShowTerms(false);
      setShowContact(false);
      setShowPrivacy(true);
    } else if (hash === "#terms") {
      setShowPrivacy(false);
      setShowContact(false);
      setShowTerms(true);
    } else if (hash === "#contact") {
      setShowPrivacy(false);
      setShowTerms(false);
      setShowContact(true);
    }
  }, []);


  return (
    <VideoBackground>
      <nav className="flex items-center justify-between flex-wrap bg-black p-6">
        <div className="flex items-center text-white mr-6">
          <a href="#" className="hover:cursor-pointer">
            <img src={icon} alt="Logo" className="w-12 hover:cursor-pointer rounded" onClick={() => {
              setShowTerms(false);
              setShowPrivacy(false);
              setShowContact(false);
            }} />
          </a>
          <h2 className="m-0 ml-4">TRINITY</h2>
        </div>
        <div className="block lg:hidden">
          <button className="flex items-center px-3 py-2 border rounded text-primary border-trinity" onClick={() => { setShowMenu(!showMenu) }}>
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>
          { showMenu &&
            <div className="absolute flex flex-col w-full left-0 items-end p-4 bg-black">
              <a href="#privacy" className="m-2 text-primary hover:cursor-pointer" onClick={() => {
                setShowTerms(false);
                setShowContact(false);
                setShowPrivacy(true);
              }}>
                Privacy Policy
              </a>
              <a href="#terms" className="m-2 text-primary hover:cursor-pointer" onClick={() => {
                setShowPrivacy(false);
                setShowContact(false);
                setShowTerms(true);
              }}>
                Terms & Conditions
              </a>
              <a href="#contact" className="m-2 text-primary hover:cursor-pointer" onClick={() => {
                setShowPrivacy(false);
                setShowTerms(false);
                setShowContact(true);
              }}>
                Contact Us
              </a>
            </div>
          }
        </div>
        <div className="hidden w-full lg:flex lg:visible lg:items-center lg:w-auto">
          <div className="text-sm lg:flex-grow">
            <a href="#privacy" className="block mt-4 lg:inline-block lg:mt-0 text-primary hover:cursor-pointer mr-4" onClick={() => {
                setShowTerms(false);
                setShowContact(false);
                setShowPrivacy(true);
              }}>
              Privacy Policy
            </a>
            <a href="#terms" className="block mt-4 lg:inline-block lg:mt-0 text-primary hover:cursor-pointer mr-4" onClick={() => {
                setShowPrivacy(false);
                setShowContact(false);
                setShowTerms(true);
              }}>
              Terms & Conditions
            </a>
            <a href="#contact" className="block mt-4 lg:inline-block lg:mt-0 text-primary hover:cursor-pointer mr-4" onClick={() => {
                setShowPrivacy(false);
                setShowTerms(false);
                setShowContact(true);
              }}>
                Contact Us
              </a>
          </div>
        </div>
      </nav>
      <div className="flex flex-col items-center text-center overflow-auto h-full text-white p-4 lg:px-72 pb-32">
        { showPrivacy ?
          <div className="">
            <Privacy></Privacy>
          </div>
        : showTerms ?
          <div className="">
            <Terms></Terms>
          </div>
        : showContact ?
          <div className="">
            <Contact></Contact>
          </div>
        :
          <div className="flex flex-col items-center py-8 md:flex-row md:py-36">
            <a href="https://studio.trinityapp.io" target="_blank" rel="noreferrer" className="my-4 w-36 cursor-pointer md:mx-8 md:w-72" >
              <img src={studio} alt="AI Studio" />
            </a>
            <a href="https://apps.apple.com/us/app/trinity-pet-ai/id6504121335" target="_blank" rel="noreferrer" className="my-4 w-36 cursor-pointer md:mx-8 md:w-72">
              <img src={pet} alt="Trinity Pet" />
            </a>
            <a href="https://apps.apple.com/us/app/trinity-ai/id6495369160" target="_blank" rel="noreferrer" className="my-4 w-36 cursor-pointer md:mx-8 md:w-72">
              <img src={ai} alt="Trinity AI" />
            </a>
          </div>
        }
      </div>
    </VideoBackground>
  );
}

export default App;
